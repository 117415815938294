import { Hsl, Lab } from '../types/colors';

const get_Cs = (L: number, a_: number, b_: number) => {
  const C_0 = 0.075 * L;
  const C_mid = 0.5 * L;
  const C_max = (0.9 * L) / Math.sqrt(a_ * a_ + b_ * b_);
  return { C_0, C_mid, C_max };
};

const pi = Math.PI;

const toe = (x: number) => {
  const k_1 = 0.206;
  const k_2 = 0.03;
  const k_3 = (1 + k_1) / (1 + k_2);
  return (
    0.5 *
    (k_3 * x -
      k_1 +
      Math.sqrt((k_3 * x - k_1) * (k_3 * x - k_1) + 4 * k_2 * k_3 * x))
  );
};

export function okLabOkHsl(lab: Lab): Hsl {
  const labA = lab[1];
  const labB = lab[2];
  const labL = lab[0];

  const C = Math.sqrt(labA * labA + labB * labB);
  const a_ = labA / C;
  const b_ = labB / C;

  const L = labL;
  const h = 0.5 + (0.5 * Math.atan2(-labB, -labA)) / pi;

  const cs = get_Cs(L, a_, b_);
  const C_0 = cs.C_0;
  const C_mid = cs.C_mid;
  const C_max = cs.C_max;

  // Inverse of the interpolation in okhsl_to_srgb:

  const mid = 0.8;
  const mid_inv = 1.25;

  let s;
  if (C < C_mid) {
    const k_1 = mid * C_0;
    const k_2 = 1 - k_1 / C_mid;

    const t = C / (k_1 + k_2 * C);
    s = t * mid;
  } else {
    const k_0 = C_mid;
    const k_1 = ((1 - mid) * C_mid * C_mid * mid_inv * mid_inv) / C_0;
    const k_2 = 1 - k_1 / (C_max - C_mid);

    const t = (C - k_0) / (k_1 + k_2 * (C - k_0));
    s = mid + (1 - mid) * t;
  }

  const l = toe(L);
  return [h, s, l];
}
