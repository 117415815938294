import { bitcoin } from '@protocol/chains';
import {
  base,
  baseSepolia,
  lukso,
  luksoTestnet,
  mainnet,
  optimism,
  optimismSepolia,
  sepolia,
} from 'viem/chains';
import { BASE_HIRAETH_CHAIN_PROPS } from './base';
import { BASE_SEPOLIA_HIRAETH_CHAIN_PROPS } from './base-sepolia';
import { HOMESTEAD_HIRAETH_CHAIN_PROPS } from './homestead';
import { LUKSO_HIRAETH_CHAIN_PROPS } from './lukso';
import { LUKSO_TESTNET_HIRAETH_CHAIN_PROPS } from './lukso-testnet';
import { OPTIMISM_HIRAETH_CHAIN_PROPS } from './optimism';
import { OPTIMISM_SEPOLIA_HIRAETH_CHAIN_PROPS } from './optimism-sepolia';
import { SEPOLIA_HIRAETH_CHAIN_PROPS } from './sepolia';
import { HiraethMultiChainProps } from './types';

export const hiraethMultiChainProps: HiraethMultiChainProps = {
  [mainnet.id]: HOMESTEAD_HIRAETH_CHAIN_PROPS,
  [base.id]: BASE_HIRAETH_CHAIN_PROPS,
  [sepolia.id]: SEPOLIA_HIRAETH_CHAIN_PROPS,
  [baseSepolia.id]: BASE_SEPOLIA_HIRAETH_CHAIN_PROPS,
  [optimism.id]: OPTIMISM_HIRAETH_CHAIN_PROPS,
  [optimismSepolia.id]: OPTIMISM_SEPOLIA_HIRAETH_CHAIN_PROPS,
  [lukso.id]: LUKSO_HIRAETH_CHAIN_PROPS,
  [luksoTestnet.id]: LUKSO_TESTNET_HIRAETH_CHAIN_PROPS,
  [bitcoin.id]: null,
};
