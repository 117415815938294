import { sepolia } from 'viem/chains';
import { DEFAULT_MAP_EVM_OPTIONS } from '../mapper/evm';
import {
  PATTERN_METADATA_LANGUAGE,
  PATTERN_METADATA_SYMBOLS,
} from './defaults';
import { HiraethChainProps } from './types';

export const BASE_SEPOLIA_HIRAETH_CHAIN_PROPS: HiraethChainProps = {
  symbolsPatternMetadata: PATTERN_METADATA_SYMBOLS,
  languagePatternMetadata: PATTERN_METADATA_LANGUAGE,
  chainRandomSource: sepolia.id,
  evmMapOptions: DEFAULT_MAP_EVM_OPTIONS,
};
