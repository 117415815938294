import { luksoTestnet } from 'viem/chains';
import { type MapEvmOptionsV2 } from '../mapper/evm';
import {
  PATTERN_METADATA_LANGUAGE,
  PATTERN_METADATA_SYMBOLS,
} from './defaults';
import { HiraethChainProps } from './types';

export const LUKSO_TESTNET_HIRAETH_CHAIN_PROPS: HiraethChainProps<MapEvmOptionsV2> =
  {
    symbolsPatternMetadata: PATTERN_METADATA_SYMBOLS,
    languagePatternMetadata: PATTERN_METADATA_LANGUAGE,
    chainRandomSource: luksoTestnet.id,
    // encodedColorStringMap: {
    //   1: '0x00778de0ffb2d6eaffb8c2ad9000b6b6aeb3001900380c0c800f7efe80400000',
    // },
    evmMapOptions: {
      maxGasPerTxn: 5000000, // about as much to deploy a max size contract
      lightingRandomSourceStepSize: 200,
      corpusMaxSizeInBytes: 50000, // max contract size
      degradationBaseInDays: 31,
      degradationBase: 7,
      transferEventSignatures: [
        '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef',
        // LSP 8 Transfer Event
        '0xb333c813a7426a7a11e2b190cad52c44119421594b47f6f32ace6d8c7207b2bf',
        // LSP 9 Transfer Event
        '0x3997e418d2cef0b3b0e907b1e39605c3f7d32dbd061e82ea5b4a770d46a160a6',
      ],
      txnTypeRandomSourceBase: 3,
      version: '2.0.0',
      eventEmitterCrestThreshold: 4,
      maxEventEmitterCrests: 2,
      eventEmitterColorOverrideThreshold: 4,
    },
  };
